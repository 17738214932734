import { useState, useEffect } from "react";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomersTable = props => {
  const isMobileOrTablet = useResponsive("down", "md");
  const { inline, showHeader, width, partner, user, advisor, customers, setCustomerState, caseFilterContext, nextStepOptions, onWorkflowSwitched } = props;
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [copyList, setCopyList] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [customerModalView, setCustomerModalView] = useState("overview");
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const [newCustomerAdded, setNewCustomerAdded] = useState(null);

  let columns = [];
  columns.push(      
    {
      header: "",
      id: 'menuButton',
      accessor: "menuButton",
      width: "2.5%",        
      disablePadding: true,
      disableSort: true
    },
    ...(!isMobileOrTablet ? [{
      header: "",
      id: 'infoIcon',
      accessor: "infoIcon",
      width: "2.5%",
      disablePadding: true,
      disableSort: true
    }] : []),
    {
      header: "Namn",
      accessor: "name",
      width: "40.5%"
    },
    {
      header: `E-postadress`,
      accessor: "email",
      width: "25%",
    },
    {
      header: `Senaste rådgivning`,
      accessor: "last_signed_document",
      width: "32%"
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateCustomers = (customer, notesCount = false) => {
    // find existing index
    let index = customers.findIndex(c => 
      c.client_id && customer.clientId && c.client_id === customer.clientId || 
      c.clientId && customer.clientId && c.clientId === customer.clientId ||
      c.client_id && customer.client_id && c.client_id === customer.client_id
    );

    if (notesCount !== false) {
      customers[index].notesCount = notesCount;
      return setCopyList(customers);
    }

    const isNewCustomer = index === -1;
    // if not found, add to end (new customer)
    if (isNewCustomer) {
      index = customers.length;
      setNewCustomerAdded(customer);
    }
    // update customers with new customer record
    customers[index] = customer;
    // sort alphabetically
    customers.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
    );

    // if a customer is selected, update it (edit from customer modal)
    if (selectedCustomer) setSelectedCustomer(customer);

    return setCopyList(customers);
  };

  const requestSearch = searched => {
    if (customers) {
      if (page > 0) setPage(0);
      const searchString = searched.toLowerCase();
      setCopyList(
        customers.filter(
          customer =>
            (customer.first_name + " " + customer.last_name)
              .toLowerCase()
              .includes(searchString) ||
            customer.id?.toLowerCase().includes(searchString) ||
            customer.name?.toLowerCase().includes(searchString)
        )
      );
    }
  };

  const toggleAddCustomerModal = action => {
    if (!customerModalOpen && (action === "add" || addCustomerModalOpen)) setSelectedCustomer(null);

    if (addCustomerModalOpen) { // disable/enable background content scrolling while modal is open    
      document.documentElement.style.overflow = "auto";
    } else {
      document.documentElement.style.overflow = "hidden";
    }

    setAddCustomerModalOpen(!addCustomerModalOpen);
  };

  const handleClientClick = (client, isNotesClick) => async e => {
    e.preventDefault();
    setCustomerState({
      isCompany: false,
      ssnOrg: client.id ? (client.id).replace("-", "") : "",
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1,
      customerModalIsOpen: true,
    }, () =>
		{
      setNewCustomerAdded(null);
      setCustomerModalOpen(true);
      if (isNotesClick) setCustomerModalView("notes");
      setSelectedCustomer(client);
      document.documentElement.style.overflow = "hidden";
		});
  };

  const handleClientModalClose = () => {
    setCustomerState({
      isCompany: false,
      ssnOrg: "",
      customerModalIsOpen: false
    }, () =>
		{
      setCustomerModalView("overview");
      setCustomerModalOpen(false);
      setSelectedCustomer(null);
		});
  };

  useEffect(() => {
    // Only set copyList if customers is an array (fetching data completed)
    if (!Array.isArray(customers)) return;

    setCopyList(customers);
    setLoaded(true);
  },[customers]);

  return {
    loaded,
    inline,
    showHeader,
    width,
    isMobileOrTablet,
    partner,
    user,
    advisor,
    columns,
    copyList,
    page,
    setPage,
    order,
    orderBy,
    rowsPerPage,
    customerModalOpen,
    customerModalView,
    selectedCustomer,
    setSelectedCustomer,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    toggleAddCustomerModal,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    nextStepOptions,
    setCustomerState,
    onWorkflowSwitched,
    addCustomerModalOpen,
    newCustomerAdded
  };
};

export default useCustomersTable;