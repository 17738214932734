import { useState, useEffect } from "react";
import i18n from "@helpers/i18n";
import api from "@helpers/api";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomerModal = props => {
  const { partner, visible, user, advisor, client, view, isSession, onClose, updateCustomers, caseFilterContext, addCustomerModalOpen, toggleAddCustomerModal, setCustomerState, onWorkflowSwitched, nextStepOptions, setSelectedCustomer } = props;
  const [loaded, setLoaded] = useState(false);
  const [currentView, setCurrentView] = useState(view);
  const [isVisible, setIsVisible] = useState(visible);
  const isMobileOrTablet = useResponsive("down", "md");
  const [notes, setNotes] = useState(false);


  let viewItems = [...partner.modules];
  // Remove views which are not used on this menu (maybe add own config setting?)
  viewItems.forEach((item) => {
    if (
      item.key === "start" ||
      item.key === "poa" ||
      item.key === "kyc" ||
      item.key === "other" ||
      item.key === "reports"
    ) {
      viewItems = viewItems.filter((i) => i.key !== item.key);
    }
  });

  // Add menu specific options to the start
  viewItems.unshift(
    { key: "overview" }
  );

  // Push menu specific options to the end
  viewItems.push(
    // { key: "logs" },
    { key: "notes" }
  );

  const views = viewItems.map(item => {
    return {
      name: item.key,
      label: i18n("dashboard_selector", item.key)
    };
  });
  
  const handleViewChange = (e, newValue) => {
    if (caseFilterContext) caseFilterContext.setCaseFilter(null); // reset case filter (force using default tab)
    setCurrentView(newValue);
  };

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose();      
      document.documentElement.style.overflow = "auto";
    }
  };

  // check if props changed
  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

// get notes
useEffect(() => {
  const fetchData = async () => {
    const clientId = client.client_id || client.clientId;
    const notes = await api("client/notes", { clientId });
    
    setNotes(notes);
    setLoaded(true);
  };
  if (!loaded) fetchData();
}, [loaded, client.id])


  return {
    isMobileOrTablet,
    isVisible,
    partner,
    user,
    advisor,
    client,
    views,
    currentView,
    isSession,
    handleClose,
    handleViewChange,
    updateCustomers,
    caseFilterContext,
    addCustomerModalOpen,
    toggleAddCustomerModal,
    setCustomerState,
    onWorkflowSwitched,
    nextStepOptions,
    setSelectedCustomer,
    notes,
    setNotes
  };
};

export default useCustomerModal;