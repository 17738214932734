import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// COMPONENTS
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MuiButton from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import {createB64Context, stringifyQueryParams} from "@helpers/utils";

const CustomerActionsButton = props => {
  const {
    advisor,
    client,
    partner,
    setSelectedCustomer,
    setCustomerState,
    onWorkflowSwitched,
    cobForm,
    nextStepOptions
  } = props;

  const customerId = client.id || client.ssn || client.orgNumber;
  const [ssnOrg, setSsnOrg] = useState(customerId ? customerId.replace("-", "") : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setSelectedCustomer(client);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelection = async option => {
    const { key, type } = option;

    let selectedWorkflow = 'default';
    if (key === 'new' || key === 'futur')
    {
      const newStateProps = {
        isCompany: false,
        ssnOrg,
        needsNewDocumentation: false,
        kycCaseId: null,
        kycInfoLoading: true,
        overrideNewDocumentation: false,
        casesLoading: false,
        cases: null,
        selectedCaseId: null,
        selectedCaseIndex: -1,
        isNewCase: true,
        standaloneApplicationsChecked: [],
        customerMenuIsOpen: true,
      };

      if (key === 'futur')  {
        selectedWorkflow = 'futur';
      }

      newStateProps.selectedCaseId = null;
      newStateProps.selectedCaseIndex = -1;
            
      document.documentElement.style.overflow = "auto"; // re-enable main page scroll

      return onWorkflowSwitched(selectedWorkflow, () => {
        setCustomerState(newStateProps, () =>
        {
          cobForm.current._onSubmit();
          handleClose();
        });
      });
    }
    else if (type === "standaloneApp")
    {
      const applications = partner.standaloneApplications;      
      const app = applications.find(app => app.key === key);

      if (app === null || app === undefined)
      {
        console.error("Invalid app configuration", app.key);
        return;
      }

      // Create a checksum that the cob odin client will check to make sure that the request
      // originated in a correct way.
      const encodedContext = createB64Context("fairclient", advisor, ssnOrg, null); // needs work for citroneer support
      return window.location.href = app.url + stringifyQueryParams({
        context: encodedContext,
        token: Settings.get("AUTH_TOKEN"),
        key: app.key
      });
    }
  };

  useEffect(() => {
    const customerId = client.id || client.ssn || client.orgNumber;
    setSsnOrg(customerId ? customerId.replace("-", "") : "");
  }, [client]);

  return (
    <React.Fragment>
      <MuiButton
        id="fade-button"
        variant="contained"
        color="secondary"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "232px",
          boxShadow: 'none',
          border: '1px solid #d2dbe8',
          padding: '5px 10px',
          "&:hover": {
            backgroundColor: 'secondary.dark',
            boxShadow: 'none'
          },
        }}
      >
        <Typography variant="button" >              
          Välj åtgärd
        </Typography>
      </MuiButton>
      <Menu
        id="account-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
      >
      {nextStepOptions.map((option, index) => {
        // Only list new case, futur and standalone applications
        if (option.key === "new" || option.key === "futur" || option.type === "standaloneApp") {
          return (
            <Tooltip
              key={index}
              title={!ssnOrg ? "Kunden har inget personnummer" : ""}
              placement="right"
            >
              <Box
                sx={{
                  width: "100%",
                  cursor: !ssnOrg ? "not-allowed" : "pointer"
                }}
              >
                  <MenuItem
                    disabled={!ssnOrg}
                    onClick={()=> handleOptionSelection(option)}
                    sx={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      px: 1,
                      height: 35,
                      minHeight: "unset"
                    }}
                  >
                    {option.label}
                  </MenuItem>
              </Box>
            </Tooltip>
          );
        }
      })}
      </Menu>
    </React.Fragment>
  );
};

export default CustomerActionsButton;

CustomerActionsButton.propTypes = {
  handlers: PropTypes.object
};
