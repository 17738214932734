import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// COMPONENTS
import MenuIcon from "@mui/icons-material/Menu";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import {createB64Context, stringifyQueryParams} from "@helpers/utils";

const CustomerMenu = props => {
  const {
    advisor,
    client,
    partner,
    setSelectedCustomer,
    setCustomerState,
    onWorkflowSwitched,
    toggleAddCustomerModal,
    cobForm,
    nextStepOptions
  } = props;

  const customerId = client.id || client.ssn || client.orgNumber;
  const [ssnOrg, setSsnOrg] = useState(customerId ? customerId.replace("-", "") : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setSelectedCustomer(client);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelection = async option => {
    const { key, type } = option;

    let selectedWorkflow = 'default';
    if (key === 'new' || key === 'futur')
    {
      const newStateProps = {
        isCompany: false,
        ssnOrg,
        needsNewDocumentation: false,
        kycCaseId: null,
        kycInfoLoading: true,
        overrideNewDocumentation: false,
        casesLoading: false,
        cases: null,
        selectedCaseId: null,
        selectedCaseIndex: -1,
        isNewCase: true,
        standaloneApplicationsChecked: [],
        customerMenuIsOpen: true,
      };

      if (key === 'futur')  {
        selectedWorkflow = 'futur';
      }

      newStateProps.selectedCaseId = null;
      newStateProps.selectedCaseIndex = -1;

      return onWorkflowSwitched(selectedWorkflow, () => {
        setCustomerState(newStateProps, () =>
        {
          cobForm.current._onSubmit();
          handleClose();
        });
      });
    }
    else if (type === "standaloneApp")
    {
      const applications = partner.standaloneApplications;      
      const app = applications.find(app => app.key === key);

      if (app === null)
      {
        console.error("Invalid app configuration", app.key);
        return;
      }

      // Create a checksum that the cob odin client will check to make sure that the request
      // originated in a correct way.
      const encodedContext = createB64Context("fairclient", advisor, ssnOrg, null); // needs work for citroneer support
      return window.location.href = app.url + stringifyQueryParams({
        context: encodedContext,
        token: Settings.get("AUTH_TOKEN"),
        key: app.key
      });
    }
  };

  useEffect(() => {
    const customerId = client.id || client.ssn || client.orgNumber;
    setSsnOrg(customerId ? customerId.replace("-", "") : "");
  }, [client]);

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Välj åtgärd">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ height: "auto", p: 0, pb: 0.3, m: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon 
              sx={{
                color: "#AAA",
                width: 16,
                height: 16,
                mr: 1
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            pb: 0,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              topx: 1,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {nextStepOptions.map((option, index) => {
          // Only list new case, futur and standalone applications
          if (option.key === "new" || option.key === "futur" || option.type === "standaloneApp") {
            return (
              <Tooltip
                key={index}
                title={!ssnOrg ? "Kunden har inget personnummer" : ""}
                placement="right"
              >
                <Box
                  sx={{
                    width: "100%",
                    cursor: !ssnOrg ? "not-allowed" : "pointer"
                  }}
                >
                    <MenuItem
                      disabled={!ssnOrg}
                      onClick={()=> handleOptionSelection(option)}
                      sx={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        px: 1,
                        height: 35,
                        minHeight: "unset"
                      }}
                    >
                      {option.label}
                    </MenuItem>
                </Box>
              </Tooltip>
            );
          }
        })}
        <MenuItem
          onClick={toggleAddCustomerModal}
          sx={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            px: 1,
            height: 35,
            minHeight: "unset",
          }}
        >
          Redigera grunduppgifter
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default CustomerMenu;

CustomerMenu.propTypes = {
  handlers: PropTypes.object
};
