import React from "react";

import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MUI_TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import NotesIcon from '@mui/icons-material/SummarizeOutlined';
import CircularProgress from "@mui/material/CircularProgress";

import i18n from "@helpers/i18n";
import {getComparator, customersTableHeightPerTotalPages} from "@helpers/muiTable";

import ContentBox from "@components/ContentBox/ContentBox";
import ButtonAdd from "@components/ButtonAdd";
import EnhancedTableHead from "@cobComponents/EnhancedTableHead";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";

import useCustomersTable from "./useCustomersTable";
import CustomerModal from "@cobComponents/CustomerModal/CustomerModal";
import CustomerMenu from "@cobComponents/CustomerMenu";
import AddCustomerModal from "@cobComponents/AddCustomerModal";
import moment from "moment";

const HtmlTooltip = styled(({className, ...props}) => (
	<Tooltip {...props} classes={{popper: className}}/>))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		display: "flex",
		alignItems: "flex-start",
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		width: 450,
		maxWidth: 450,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9"
	}
}));

const formatDateAsText = inputDate => {
  if (!inputDate)
  {
    return "-";
  }

  const date = moment(new Date(inputDate), "YYYY-MM-DD HH:mm");
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  const wasUpdatedToday = date.isSame(today, 'd');
  const wasUpdatedYesterday = date.isSame(yesterday, 'd');
  const updatedDate = date.format("YYYY-MM-DD");
  const updatedHours = date.format("HH:mm");

  return (
    <React.Fragment>
      {wasUpdatedToday ? "Idag" : wasUpdatedYesterday ? "Igår" : updatedDate}
      <em style={{display: "block", color: "#95a4ac", fontStyle: "normal", lineHeight: "15px"}}>
        {updatedHours}
      </em>
    </React.Fragment>
  );
};

const CustomersTable = props =>
{
	const {
    loaded,
		inline,
		width,
		showHeader,
		isMobileOrTablet,
		partner,
    user,
		advisor,
		copyList,
    columns,
		page,
		setPage,
    order,
    orderBy,
		rowsPerPage,
		selectedCustomer,
		setSelectedCustomer,
		setRowsPerPage,
		handleChangePage,
    handleRequestSort,
		requestSearch,
		toggleAddCustomerModal,
		handleClientClick,
		handleClientModalClose,
		updateCustomers,
		caseFilterContext,
		nextStepOptions,
		setCustomerState,
    onWorkflowSwitched,
		customerModalOpen,
    customerModalView,
		addCustomerModalOpen,
    newCustomerAdded
	} = useCustomersTable(props);  

  // converting last_signed_document to timestamps(numbers) for sorting to work
  copyList.map(client => {
    client.last_signed_document = new Date(client.last_signed_document).getTime() || 0;
  });

	return (
    <ContentBox className={inline ? "inline" : ""} style={{ width: width }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #ededed",
          pb: 1.5,
          mb: 2
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: 500,
            color: "primary.main",
            lineHeight: 0.9
          }}
        >
          {showHeader && i18n("dashboard_selector", "customers")}
        </Typography>
        <ButtonAdd
          label="Skapa ny kund"
          onClick={() => toggleAddCustomerModal("add")}
        />
      </Box>
      <Box
        sx={{
          width: "100%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            mb: 1
          }}
        >
          <MUI_TextField
            size="small"
            fullWidth
            placeholder="Sök på namn, person- eller organisationsnummer"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%"
              }
            }}
            sx={{
              width: "60%",
              display: "flex"
            }}
          />
        </Box>
        {newCustomerAdded && (
          <div
            className="sign-on-screen-notice Notice warning custom-message"
            style={{ maxWidth: "unset" }}
          >
            <Typography variant="body2" color="primary.main">
              <b>
                Sparade kund:{" "}
                <Link
                  underline="none"
                  color="#4cb7d5"
                  onClick={handleClientClick(newCustomerAdded)}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {newCustomerAdded.name} (
                  {newCustomerAdded.ssn ||
                    newCustomerAdded.orgNumber ||
                    newCustomerAdded.id ||
                    "-"}
                  )
                </Link>
              </b>
            </Typography>
          </div>
        )}
        <TableContainer component={Box} sx={{ overflowY: "hidden" }}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              height: loaded ? "unset" : "400px",
              minHeight:
                customersTableHeightPerTotalPages[rowsPerPage] || "unset",
              border: 0,
              overflow: "auto"
            }}
          >
            {!loaded ? (
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="button" size={36} />
              </Box>
            ) : (
              <Table
                aria-label="custom pagination table"
                sx={{
                  mt: 1,
                  border: 0
                }}
              >
                <EnhancedTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? copyList
                        .sort(getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                    : copyList.sort(getComparator(order, orderBy))
                  )
                    // (copyList.length > 0 ? copyList : rows)
                    .map((client, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          height: 48,
                          ...(key % 2 !== 0 && {
                            "& > td": { background: "#f5f9ff" }
                          })
                        }}
                      >
                        <TableCell
                          sx={{
                            pl: 0.5,
                            pr: 0,
                            py: 0,
                            borderBottom: "none"
                          }}
                        >
                          <CustomerMenu
                            advisor={advisor}
                            client={client}
                            partner={partner}
                            nextStepOptions={nextStepOptions}
                            setSelectedCustomer={setSelectedCustomer}
                            setCustomerState={setCustomerState}
                            onWorkflowSwitched={onWorkflowSwitched}
                            toggleAddCustomerModal={toggleAddCustomerModal}
                            cobForm={caseFilterContext.cobForm}
                          />
                        </TableCell>
                        {!isMobileOrTablet && (
                          <TableCell
                            sx={{
                              pl: 0,
                              pr: 0,
                              py: 0,
                              borderBottom: "none"
                            }}
                          >
                            <HtmlTooltip
                              disableFocusListener
                              placement="left"
                              title={
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    padding: 1.5,
                                    alignItems: "flex-start"
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "flex-start",
                                      borderBottom: "1px solid #000"
                                    }}
                                  >
                                    <Typography color="inherit" variant="h5">
                                      {client.name}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      mt: 2,
                                      display: "flex",
                                      alignItems: "flex-start"
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        mt: 0.6
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        <b>Person- lorg.nummer</b>
                                      </Typography>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        {client.id ||
                                          client.ssn ||
                                          client.orgNumber}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        mt: 0.6
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        <b>Epost</b>
                                      </Typography>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        {client.email}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        mt: 0.6
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        <b>Telefon</b>
                                      </Typography>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        {client.phone || client.phoneNumber}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        mt: 0.6
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        <b>Adress</b>
                                      </Typography>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        {client.street}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        mt: 0.6
                                      }}
                                    >
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        <b>KYC-Datum</b>
                                      </Typography>
                                      <Typography
                                        color="inherit"
                                        variant="body2"
                                        sx={{ width: "48%" }}
                                      >
                                        {client.kyc_date}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              }
                            >
                              <InfoIcon
                                sx={{
                                  mt: 0.45,
                                  fontSize: "18px",
                                  color: "#78B2CB"
                                }}
                              />
                            </HtmlTooltip>
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "secondary.lighter",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            paddingLeft: "10px"
                          }}
                        >
                          <Link
                            variant="tableCell"
                            href="#"
                            onClick={handleClientClick(client)}
                            sx={{
                              display: "inline-block",
                              width: "auto",
                              textDecoration: "none",
                              span: {
                                textDecoration: "underline"
                              }
                            }}
                          >
                            <span>{client.name}</span>
                            <em
                              style={{
                                display: "block",
                                color: "#95a4ac",
                                fontStyle: "normal",
                                lineHeight: "15px"
                              }}
                            >
                              {client.id || client.ssn || client.orgNumber}
                            </em>
                          </Link>
                          {client.notesCount > 0 && (
                            <Link
                              variant="tableCell"
                              onClick={
                                client.notesCount < 1
                                  ? () => null
                                  : handleClientClick(client, true)
                              }
                              sx={{
                                display: "inline-block",
                                width: "20%",
                                textDecoration: "none",
                                cursor:
                                  client.notesCount < 1 ? "auto" : "pointer",
                                ":hover": {
                                  filter: "brightness(100%)" // disable hover effect
                                }
                              }}
                            >
                              <NotesIcon
                                sx={{
                                  display: "inline-block",
                                  verticalAlign: "super",
                                  // mt: 0.45,
                                  ml: 1,
                                  fontSize: "18px",
                                  color:
                                    client.notesCount < 1
                                      ? "secondary.main"
                                      : "tertiary.darker"
                                }}
                              />
                            </Link>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {client.email}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            px: 0,
                            py: 0.5,
                            borderBottom: "none",
                            color: "inherit",
                            textAlign: "right",
                            pr: 1
                          }}
                        >
                          <Typography
                            variant="tableCell"
                            color="secondary.lighter"
                          >
                            {formatDateAsText(client.last_signed_document)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {copyList.length < 1 && (
                    <TableRow>
                      <TableCell sx={{ border: 0 }} colSpan={5}>
                        Inga resultat.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </Box>
          <CustomTableFooter
            loaded={true}
            type="customers"
            totalCases={copyList.length}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
      {customerModalOpen && selectedCustomer && (
        <CustomerModal
          view={customerModalView}
          visible={true}
          partner={partner}
          user={user}
          advisor={advisor}
          client={selectedCustomer}
          onClose={handleClientModalClose}
          updateCustomers={updateCustomers}
          caseFilterContext={caseFilterContext}
          addCustomerModalOpen={addCustomerModalOpen}
          toggleAddCustomerModal={toggleAddCustomerModal}
          setCustomerState={setCustomerState}
          onWorkflowSwitched={onWorkflowSwitched}
          nextStepOptions={nextStepOptions}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
      {addCustomerModalOpen && (
        <AddCustomerModal
          open={addCustomerModalOpen}
          toggleModal={toggleAddCustomerModal}
          customer={selectedCustomer}
          userData={{ personal_number: advisor.ssn, role: advisor.role }}
          updateCustomers={updateCustomers}
          allowNoCustomerId={partner._data.allowNoCustomerId}
        />
      )}
    </ContentBox>
  );
};

export default CustomersTable;
