import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import MUI_TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import InputAdornment from "@mui/material/InputAdornment";
import searchIcon from "@assets/img/search_icon.svg";

import useSearchBar from "./useSearchBar";

const SearchBar = props => {
  const {
    searchInput,
    requestSearch,
    customerList,
    nextStepOptions,
    handleOptionSelection
  } = useSearchBar(props);

  const expanded = (customerList.length > 0);

  return (
      <Accordion
        expanded={expanded}
        sx={{
          display: "inline-block",
          height: "min-content",
          minWidth: 400,
          width: "min-content",
          position: "fixed",
          top: 10,
          right: 260,
          margin: "0 !important",
          borderRadius: "20px !important",
          backgroundColor: "#fff",
          "& .MuiAccordionSummary-root": {
            minHeight: "40px",
            px: 0,
            borderRadius: "20px",
            backgroundColor: "transparent",
          },      
          "& .MuiAccordionSummary-root.Mui-expanded": {
            width: "100%",
            minHeight: "40px",
            margin: 0,
            borderColor: "rgba(0,0,0,0.2) !important",
            backgroundColor: "transparent !important",
          },
          "& .MuiAccordion-region": { 
            width: "100%",
          },
          "&::before": {
            position: "relative"          
          }   
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            width: "100%",
            flexDirection: "row",
            px: 1,
            margin: 0,
            "& .Mui-expanded": { margin: "inherit" },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0
            },
            "& .MuiAccordionSummary-content": {
              flexDirection: "row",
              width: "100%",
              margin: "inherit" 
            },
          }}
        >        
        <Box
          sx={{
            width: "auto",
            display: "flex",
            flexGrow: 1,
            justifyContent: "right",
            mt: 0
          }}
        >
          <MUI_TextField
            size="small"
            placeholder="Ange kundens namn, person- eller orgnummer"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            autoComplete="off"
            InputProps={{
              inputRef: searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {                
                width: "100%",
                borderRadius: "20px",
                height: 40,
              }
            }}
            sx={{
              width: "100%",
              borderRadius: "20px",
              height: 40,
              backgroundColor: "#fff",
              "& fieldset": {                
                ...(expanded && {
                  borderColor: "rgba(0,0,0,0.2) !important",
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderBottom: "none !important",
                  "&.MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px !important",
                  }
                }),
              },
              "& .MuiInputBase-input": {
                width: "94%",
                height: 40,
                paddingLeft: 1,
                fontSize: "1rem"
              },
            }}
          />
        </Box>
        </AccordionSummary>
        <AccordionDetails 
          sx={{
            width: "100%",
            p: 0,
            border: "1px solid #000",
            borderTop: 0,
            borderColor: "rgba(0,0,0,0.2)",
            // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px"
          }}>
          <List
            sx={{
              height: "auto",
              width: "95%",
              maxWidth: "unset",
              maxHeight: "70vh",
              borderTop: "1px solid #d4d4d4",
              bgcolor: "background.paper",
              py: 0,
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.5em",
                backgroundColor: "secondary.main"
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "grey.400",
                borderRadius: "10px"
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "secondary.main"
              }

            }}
            component="nav"
          >
            {customerList.map((customer, key) => (
              <ListItem
                key={key}
                sx={{
                  flexDirection: "column",
                  width: "100%",
                  px: 0,
                  borderBottom: "1px solid #d4d4d4",
                }}
              >
                <Box
                  sx={{
                    flexDirection: "row",
                    width: "100%"
                  }}
                >
                  <ListItemText
                    primary={customer.id}                  
                    sx={{ 
                      width: "30%",
                      alignItems: "flex-start"
                    }}
                  />
                  <ListItemText
                    primary={customer.first_name || customer.last_name ? `${customer.first_name} ${customer.last_name}` : customer.name}              
                    sx={{ 
                      width: "70%",
                      alignItems: "flex-start",
                      "& span": {fontWeight: 500}
                    }}
                  />
                </Box>
                <Box
                    sx={{
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                  {nextStepOptions.map((option, index) => {
                  // Only list new case and standalone applications
                    if (option.key === "new" || option.type === "standaloneApp") {
                      return (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            px: 0,
                            paddingLeft: "114px",
                            height: 25,
                            minHeight: "unset",
                            display: index === 1 ? "none" : "flex",
                          }}
                        >
                          <Typography
                            onClick={()=> handleOptionSelection(customer, option)}
                            sx={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "primary.main",
                            }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
                </Box>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  handlers: PropTypes.object
};
